import React from 'react'
import { Link } from 'react-router-dom'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
const Ppc = () => {
  return (
    <div className="mil-wrapper">
    <Navbar/>

   {/* <!-- banner --> */}
   <div className="mil-banner-sm bg-white shadow shadow-bottom shadow-md">
    <div className="mil-banner-content">
        <div className="container mil-relative">
            <div className="row d-flex flex-column-reverse flex-md-row">
                <div className="col-md-8">
                    <ul className="mil-breadcrumbs mil-mb-30 ">
                        <li>
                            <Link to="/home">Home</Link>
                        </li>
                        <li>
                            <Link to="">Service</Link>
                        </li>
                        <li>
                            <Link to="/salesforce">PPC</Link>
                        </li>
                        {/* <li>
                            <Link to="/Salesforce">PPC</Link>
                        </li> */}
                    </ul>
                    <h2 className="mil-uppercase" style={{ color: "#1B2F5B" }}>
                    Pay Per Click
                    </h2>
                </div>
                <div className="col-md-4 text-center">
                    <img src="/assets/mockup/PPC.png" alt="mus" className="img-fluid" />
                </div>
            </div>
        </div>
        <div className="mil-fixed-button-container text-center mt-3">
            <button className="mil-vertical-button text-dark">
                <Link to='/contact'>Get a Free Quote!</Link>
            </button>
        </div>
    </div>
</div>

      {/* <!-- banner end --> */}

    {/* <!-- call to action --> */}
    <section className="mt-5">
      <div
        className="mil-deco"
        style={{ bottom: "0", right: "25%", transform: "rotate(180deg)" }}
      ></div>
      <div className="container">
        <div className="row">
          <div className="">
            <div className=" mil-mb-30">
              <p className='conten'>
                
                Achieve instant visibility and results with our PPC campaigns. Our team creates precision-targeted ad campaigns to drive valuable traffic and convert visitors into loyal customers, all while keeping the approach cost-effective. Digital landscape is highly competitive and so there is a cost assigned to each click. In absence of knowledge and expertise, chances that one might end up losing a chunk of digital marketing spend are relatively higher.
</p>            </div>
<div className=" mil-mb-30">
              <p className='conten'>
                
                So when you choose us as your PPC partner, you don’t just invest in online advertising but you invest in a process that is driven by research, strategy, precision, data and creativity. The campaigns we design for you are meticulously designed results of intense brainstorming sessions, concept development, creative strategy, all underpinned by deep research</p>            </div>
          </div>
        </div>
      </div>
    </section>
    {/* <!-- call to action end --> */}

    <div className="container">
      <div className="mil-divider"></div>
    </div>

    {/* <!-- steps --> */}
    <section className="mt-4">
      {/* <div
        className="mil-deco"
        style={{ bottom: "0", right: "35%", transform: "rotate(180deg)" }}
      ></div> */}
      <div className="container">
        <div className="row">
          <div className="col-12 mil-mb-90 ">
            {/* <span className="mil-suptitle mil-suptitle-2 mil-mb-30">
              Services and Solutions
            </span> */}
            <h2 className="mil-mb-30">Our Comprehensive PPC Services   </h2>
            <p className="mil-dark">
            At BytesFarms Technologies, we offer a comprehensive suite of PPC services to help you maximize your ROI:   </p>
          </div>
        </div>
        <div className="row mil-mb-30-adapt">
          <div className="col-xl-4">
            <div className="mil-mb-60 conten">
              <div className="mil-number-icon mil-circle mil-mb-30">
                <span>01</span>
              </div>
              <h4 className="mil-mb-15">Google Ads</h4>
              <p>
              Enables the brand to display their ads to their targeted customers when they search for specific keywords on any page of the google network.</p>
              
            </div>
          </div>
          <div className="col-xl-4">
            <div className="mil-mb-60 conten">
              <div className="mil-number-icon mil-lines mil-mb-30">
                <span>02</span>
              </div>
              <h4 className="mil-mb-15">Display Advertising</h4>
              <p>
              Designs the most creative and interactive visual ads to promote the product on different platforms. </p>
            </div>
          </div>
          <div className="col-xl-4">
            <div className="mil-mb-60 conten">
              <div className="mil-number-icon mil-mb-30">
                <span>03</span>
              </div>
              <h4 className="mil-mb-15">PPC Audits</h4>
              <p>
              Backed by data and through analysis, a comprehensive report is prepared to evaluate the performance, identify the gaps and improve further.</p>
            </div>
          </div>
          
          
          
        </div>
      </div>
    </section>
    {/* <!-- steps end --> */}

    <div className="container">
      <div className="mil-divider"></div>
    </div>

    <div className="container">
      <div className="mil-divider"></div>
    </div>

   

   
    {/* <!-- services --> */}
     {/* <!-- services --> */}
      
    <section className="bg-white mil-deco-right mil-p-120-0">
        <div
          className="mil-deco mil-deco-accent"
          style={{ top: "0", left: "5%" }}
        ></div>
        <div className="container">
          <div className="mil-text-center mil-mb-90">
           
            <h2 className="mil-ligh mil-mb-30">
              {" "}
              Why Choose our{" "}
              <span className="mil-accent">Search Engine Optimization</span>{" "}
            </h2>
           
          </div>
          <div className="row mil-mb-30-adapt">
            <div className="col-lg-3">
              <div className="mil-icon-box-head mil-long mil-mob-center mil-mb-60">
                <div className="mil-icon-frame mil-icon-frame-sm mil-ligh">
                  <img src="/assets/icon/sm/6.svg" alt="icon" />
                </div>
                <p className="mil-ligh"> Strategin Campaign Creation</p>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="mil-icon-box-head mil-long mil-mob-center mil-mb-60">
                <div className="mil-icon-frame mil-icon-frame-sm mil-ligh">
                  <img src="/assets/icon/sm/6.svg" alt="icon" />
                </div>
                <p className="mil-ligh">Audience & Device Analysis</p>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="mil-icon-box-head mil-long mil-mob-center mil-mb-60">
                <div className="mil-icon-frame mil-icon-frame-sm mil-ligh">
                  <img src="/assets/icon/sm/6.svg" alt="icon" />
                </div>
                <p className="mil-ligh">Custom Conversion Analysis</p>
              </div>
            </div>
            

            

            
          </div>
          {/* <div className="mil-text-center">
            <Link
              to="/Contact"
              className="mil-button mil-border mil-ligh mil-mb-30"
            >
              <span>Talk To Our SEO Experts</span>
            </Link>
          </div> */}
        </div>
      </section>
    {/* <!-- services end --> */}

    <Footer />
  </div>
  )
}

export default Ppc