import React from "react";
import "./Service.css";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
const WebApp = () => {
  return (
    <div className="mil-wrapper">
      <Navbar/>

     {/* <!-- banner --> */}
     {/* <div className="mil-banner-sm bg-white shadow shadow-bottom shadow-md">
        
        <div className="mil-banner-content">
          <div className="container mil-relative">
            <ul className="mil-breadcrumbs mil-mb-30">
              <li>
                <Link to="/Home">Home</Link>
              </li>
              <li>
                <Link to="">Service</Link>
              </li>
              <li>
                <Link to="/Salesforce">Web Application</Link>
              </li>
            </ul>
            <div className="d-flex">
              <div className="d-flex row">
                <h2 className="mil-uppercase" style={{ color: "#1B2F5B" }}>
                  Web App Developement
                </h2>

                <p className="col-md-8 conten">
                Are you ready to transform your online presence? Our Web
                  Development services are designed to create stunning,
                  functional, and user-friendly websites that resonate with your
                  audience and drive results. With a team of skilled developers,
                  designers, and experts, we bring your vision to life on the
                  digital canvas. Whether you're a startup looking to establish
                  your online identity or a well-established business aiming to
                  revamp your website, we've got you covered.
                </p>
              </div>
              <img
                src="assets/mockup/webdev.png"
                alt="mus"
                className="col-md-4"
              />
            </div>
          </div>
          <div className="mil-fixed-button-container">
            <button className="mil-vertical-button text-dark ">
             <Link to='/contact'> Get a Free Quote!</Link>
            </button>
          </div>
        </div>
      </div> */}
<div className="mil-banner-sm bg-white shadow shadow-bottom shadow-md">
  <div className="mil-banner-content">
    <div className="container mil-relative">
      <ul className="mil-breadcrumbs mil-mb-30">
        <li>
          <Link to="/home">Home</Link>
        </li>
        <li>
          <Link to="">Service</Link>
        </li>
        <li>
          <Link to="/salesforce">Web Application</Link>
        </li>
      </ul>
      <div className="d-flex flex-column-reverse flex-md-row">
        <div className="d-flex flex-column col-md-8">
          <h2 className="mil-uppercase mt-2" style={{ color: "#1B2F5B" }}>
          Web & App Developement
          </h2>

          <p className="conten">
          Across various sectors, companies are increasingly embracing tailored technological solutions to optimize their business processes and streamline their workflows. Technology has become the driving force behind these essential functions, from marketing and sales to overall operations.
          </p>
          <p className="">In the digital age, this paradigm shift is reshaping how businesses operate, making it essential to harness the power of customized tech solutions. As you explore our website and app service section, you'll discover how our offerings can empower your business in this digital era, providing the tools you need to thrive in a tech-driven world. The transition to a digital-first approach has become the prevailing trend, and the Software as a Service (SaaS) industry has rapidly risen to prominence in the contemporary landscape.</p>

          <div className="mil-quote-button ">
            <button className="mil-vertical-button text-dark ">
             <Link to='/contact'> Get a Free Quote!</Link>
            </button>
          </div>
        </div>
        <img
          src="/assets/mockup/webdev.png"
          alt="mus"
          className="col-md-5 mockup"
        />
      </div>
    </div>
  </div>
</div>
      {/* <!-- banner end --> */}

     

     {/* <!-- call to action --> */}
     <section className="mt-4">
        <div
          className="mil-deco"
          style={{ bottom: "0", right: "25%", transform: "rotate(180deg)" }}
        ></div>
      </section>
      {/* <!-- call to action end --> */}

      <div className="mil-icon-box mil-center mil-mb-60 mt-5">
        CHALLENGES
        <h2 className="mil-mb-20 mt-2 ">What is your challenge today?</h2>
        <p className="h5">
          Explore the challenges that connect to your situation. We can work
          together to <br />
          chart the best path forward for your specific organization and
          circumstance.
        </p>
      </div>

      <div className="container">
        <Accordion
          style={{
            backgroundColor: "#3279a8",
            marginBottom: "20px",
            color: "white",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className=""
          >
            <Typography className="fw-bold mil-uppercase">
            What programming languages and technologies should I consider for a web and app development project?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
            There must be a correct programming language and technology for web and app development. It depends on factors like the application use case, user base, back, etc. Some common languages used in web and app development are HTML/CSS, JavaScript, Python, Ruby, Java, Swift, and Kotlin.


            </Typography>
            <h6 className="mt-4 mb-4 text-white font-weight-500 ">
              Related Offerings-
            </h6>
            <div className="col-md-11 d-flex">
              <div className="col-md-7">
                <div>
                Front-end Development: HTML/CSS, JavaScript, React, Angular, Vue.js, Bootstrap or Material-UI.
                  <Link to="/technologies" class="mil-link3 link-right">
                    <i class="fas fa-arrow-right"></i>
                  </Link>
                </div>{" "}
                <div>
                Back-end Development: Node.js, Ruby on Rails, Python (Django or Flask), PHP.
                  <Link to="/technologies" class="mil-link3 link-right">
                    <i class="fas fa-arrow-right"></i>
                  </Link>
                </div>
                <div>
                Database Management: MySQL, PostgreSQL, SQLite, MongoDB or Firebase.
                  <Link to="/technologies" class="mil-link3 link-right">
                    <i class="fas fa-arrow-right"></i>
                  </Link>
                </div>
              </div>
              <div className="col-md-4">
                <div>
                Continuous Integration/Continuous Deployment (CI/CD)
                  <Link to="/technologies" class="mil-link3 link-right">
                    <i class="fas fa-arrow-right"></i>
                  </Link>
                </div>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion
          style={{
            backgroundColor: "#3279a8",
            marginBottom: "20px",
            color: "white",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className=""
          >
            <Typography className="fw-bold mil-uppercase">
            What are the critical steps in the app development process, from concept to launch?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
            The process of development consists of 4 significant steps, which are planning, designing, development, and testing. When the development is completed, it is released for limited users for beta testing.
            </Typography>
            <h6 className="mt-4 mb-4 text-white font-weight-500">
              Related Offerings-
            </h6>
            <div className="col-md-11 d-flex">
              <div className="col-md-7">
                <div>
                Mobile App Development
                  <Link to="/services/mobile-app-developement" class="mil-link3 link-right">
                    <i class="fas fa-arrow-right"></i>
                  </Link>
                </div>{" "}
                <div>
                Web App Development
                  <Link to="/services/web-developement" class="mil-link3 link-right">
                    <i class="fas fa-arrow-right"></i>
                  </Link>
                </div>
                <div>
                Progressive Web Apps (PWAs)
                  <Link to="" class="mil-link3 link-right">
                    <i class="fas fa-arrow-right"></i>
                  </Link>
                </div>
              </div>
              <div className="col-md-4">
                <div>
                App Testing and Quality Assurance
                  <Link to="" class="mil-link3 link-right">
                    <i class="fas fa-arrow-right"></i>
                  </Link>
                </div>
                <div>
                IoT App Development
                  <Link to="" class="mil-link3 link-right">
                    <i class="fas fa-arrow-right"></i>
                  </Link>
                </div>
                <div>
                Custom App Solutions
                  <Link to="" class="mil-link3 link-right">
                    <i class="fas fa-arrow-right"></i>
                  </Link>
                </div>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion
          style={{
            backgroundColor: "#3279a8",
            marginBottom: "20px",
            color: "white",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className=""
          >
            <Typography className="fw-bold mil-uppercase">
            How can I choose the right web and app development team or company for my project?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
            Choosing the right team to build your online product can take time nowadays, as there are a lot of agencies and freelancers who have the assurance of delivering quality services. Still, they need to perform up to the mark. Bytesfarms takes responsibility here. With the right team of experts, we guarantee to deliver high-quality work on time
            </Typography>
            <h6 className="mt-4 mb-4 text-white font-weight-500">
             Related Offerings-
            </h6>
            <div className="col-md-11 d-flex">
              <div className="col-md-7">
                <div>
                Front-End Development
                  <Link to="/technologies" class="mil-link3 link-right">
                    <i class="fas fa-arrow-right"></i>
                  </Link>
                </div>{" "}
                <div>
                Content Management Systems (CMS) Development
                  <Link to="" class="mil-link3 link-right">
                    <i class="fas fa-arrow-right"></i>
                  </Link>
                </div>
                <div>
                E-commerce Development
                  <Link to="" class="mil-link3 link-right">
                    <i class="fas fa-arrow-right"></i>
                  </Link>
                </div>
                <div>
                Hybrid App Development
                  <Link to="" class="mil-link3 link-right">
                    <i class="fas fa-arrow-right"></i>
                  </Link>
                </div>
              </div>
               <div className="col-md-4">
                <div>
                Database Design and Management
                  <Link to="/technologies" class="mil-link3 link-right">
                    <i class="fas fa-arrow-right"></i>
                  </Link>
                </div>
                <div>
                UI/UX Design
                  <Link to="/technologies" class="mil-link3 link-right">
                    <i class="fas fa-arrow-right"></i>
                  </Link>
                </div>
                <div>
                Back-End Development
                  <Link to="/technologies" class="mil-link3 link-right">
                    <i class="fas fa-arrow-right"></i>
                  </Link>
                </div>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion
          style={{
            backgroundColor: "#3279a8",
            marginBottom: "20px",
            color: "white",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className=""
          >
            <Typography className="fw-bold mil-uppercase">
            What are the best practices for optimizing the performance of a web or mobile app?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
            There are a lot of aspects of performance optimization, for example, speed optimization, CDN optimization, graphic optimization, etc. It's essential to make the app smooth for a better user experience.
            </Typography>
            <h6 className="mt-2 mb-2 text-white font-weight-500">
              Related Offerings -
            </h6>
            <div className="col-md-11 d-flex">
              <div className="col-md-7">
                <div>
                Performance Audits and Analysis
                  <Link to="" class="mil-link3 link-right">
                    <i class="fas fa-arrow-right"></i>
                  </Link>
                </div>{" "}
                <div>
                Code Review and Optimization
                  <Link to="" class="mil-link3 link-right">
                    <i class="fas fa-arrow-right"></i>
                  </Link>
                </div>
                <div>
                Progressive Web App (PWA) Implementation
                  <Link to="" class="mil-link3 link-right">
                    <i class="fas fa-arrow-right"></i>
                  </Link>
                </div>
              </div>
              <div className="col-md-4">
                <div>
                Client-Side Performance Optimization
                  <Link to="" class="mil-link3 link-right">
                    <i class="fas fa-arrow-right"></i>
                  </Link>
                </div>
                
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion
          style={{
            backgroundColor: "#3279a8",
            marginBottom: "20px",
            color: "white",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className=""
            style={{ color: "white" }}
          >
            <Typography className="fw-bold mil-uppercase">What are the main differences between web development and app development, and when should I choose one over the other? WHAT IS BETTER PPC OR FACEBOOK ADS?{" "}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
            A website can be accessed only through a web browser, and an application is typically Software that can be installed in the operating system. The selection depends upon the user base, use case, functionality, and budget.
            </Typography>
            
          </AccordionDetails>
        </Accordion>
       
          
      </div>
      <div className="container">
        <div className="mil-divider"></div>
      </div>

      {/* <!-- services --> */}
      <section className="mil-services mil-p-120-90 ">
        {/* <div
          className="mil-deco"
          style={{ bottom: "0", right: "40%", transform: "rotate(180deg)" }}
        ></div> */}
        <div className="container">
          
          <h2 className="mil-mb-90">
            Our Approach To <span className="mil-accent">Web App</span>{" "}
            Developement
          </h2>
          <div className="row mil-mb-30-adapt">
            <div className="col-lg-6 col-xl-6">
              <div className="mil-service-item mil-without-lines mil-mb-60">
                <div className="mil-service-icon">
                  <div className="mil-icon-frame mil-icon-frame-md">
                    <img src="/assets/icon/1.svg" alt="icon" />
                  </div>
                </div>
                <div className="mil-service-text">
                  <h5 className="mil-mb-30 ">
                    <span className="mil-accent">01</span> Proven Expertise
                  </h5>
                  <p className="conten">
                   With years of experience in web and app development, our team has a deep understanding of the unique challenges and opportunities that come with admin panel development.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-xl-6">
              <div className="mil-service-item mil-without-lines mil-mb-60">
                <div className="mil-service-icon">
                  <div className="mil-icon-frame mil-icon-frame-md">
                    <img src="/assets/icon/2.svg" alt="icon" />
                  </div>
                </div>
                <div className="mil-service-text">
                  <h5 className="mil-mb-30">
                    <span className="mil-accent">02</span>Cutting-Edge Technologies
                   
                  </h5>
                  <p className="conten">
                   We stay up to date with the latest technologies and trends in admin panel development to ensure that you get a solution that is modern and future-proof.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-xl-6">
              <div className="mil-service-item mil-without-lines mil-mb-60">
                <div className="mil-service-icon">
                  <div className="mil-icon-frame mil-icon-frame-md">
                    <img src="/assets/icon/3.svg" alt="icon" />
                  </div>
                </div>
                <div className="mil-service-text">
                  <h5 className="mil-mb-30">
                    <span className="mil-accent">03</span>Client-Centric Process
                  </h5>
                  <p className="conten">
                  Your satisfaction is our top priority. We maintain open communication, keep you informed throughout the development process, and provide reliable post-launch support.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-xl-6">
              <div className="mil-service-item mil-without-lines mil-mb-60">
                <div className="mil-service-icon">
                  <div className="mil-icon-frame mil-icon-frame-md">
                    <img src="/assets/icon/4.svg" alt="icon" />
                  </div>
                </div>
                <div className="mil-service-text">
                  <h5 className="mil-mb-30">
                    <span className="mil-accent">04</span>Affordable Solutions
                  </h5>
                  <p className="conten">
                  We believe in providing quality services without breaking the bank. Our admin panel development services offer excellent value for your investment.
                  </p>
                </div>
              </div>
            </div>

            <div className="row align-items-center">
              {/* <div className="col-md-6 col-xl-6">
                <Link to="/Contact" className="mil-button mil-border mil-mb-30">
                  <span>Talk To Our Web Application Experts</span>
                </Link>
              </div> */}
              <div className="col-md-6 col-xl-6">
                <div className="mil-adaptive-right">
                  {/* <Link to="" className="mil-link mil-mb-30"><span>See More</span><i className="fas fa-arrow-right"></i></Link> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- services end --> */}
      <section className="mil-services mil-p-120-90 shadow shadow-bottom shadow-lg">
        {/* <div
          className="mil-deco"
          style={{ bottom: "0", right: "40%", transform: "rotate(180deg)" }}
        ></div> */}
        <div className="container">
          
          <h2 className="mil-mb-90">
            Our  <span className="mil-accent">Process</span>{" "}
            
          </h2>
          <div className="row mil-mb-30-adapt">
            <div className="col-lg-6 col-xl-6">
              <div className="mil-service-item mil-without-lines mil-mb-60">
                <div className="mil-service-icon">
                  <div className="mil-icon-frame mil-icon-frame-md">
                    <img src="/assets/icon/5.svg" alt="icon" />
                  </div>
                </div>
                <div className="mil-service-text">
                  <h5 className="mil-mb-30 ">
                    <span className="mil-accent">01</span> Consultation
                  </h5>
                  <p className="conten">
                   With years of experience in web and app development, our team has a deep understanding of the unique challenges and opportunities that come with admin panel development.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-xl-6">
              <div className="mil-service-item mil-without-lines mil-mb-60">
                <div className="mil-service-icon">
                  <div className="mil-icon-frame mil-icon-frame-md">
                    <img src="/assets/icon/6.svg" alt="icon" />
                  </div>
                </div>
                <div className="mil-service-text">
                  <h5 className="mil-mb-30">
                    <span className="mil-accent">02</span>Design & Developement
                   
                  </h5>
                  <p className="conten">
                   We stay up to date with the latest technologies and trends in admin panel development to ensure that you get a solution that is modern and future-proof.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-xl-6">
              <div className="mil-service-item mil-without-lines mil-mb-60">
                <div className="mil-service-icon">
                  <div className="mil-icon-frame mil-icon-frame-md">
                    <img src="/assets/icon/7.svg" alt="icon" />
                  </div>
                </div>
                <div className="mil-service-text">
                  <h5 className="mil-mb-30">
                    <span className="mil-accent">03</span>Testing
                  </h5>
                  <p className="conten">
                  Your satisfaction is our top priority. We maintain open communication, keep you informed throughout the development process, and provide reliable post-launch support.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-xl-6">
              <div className="mil-service-item mil-without-lines mil-mb-60">
                <div className="mil-service-icon">
                  <div className="mil-icon-frame mil-icon-frame-md">
                    <img src="/assets/icon/8.svg" alt="icon" />
                  </div>
                </div>
                <div className="mil-service-text">
                  <h5 className="mil-mb-30">
                    <span className="mil-accent">04</span>Deployment
                  </h5>
                  <p className="conten">
                  We believe in providing quality services without breaking the bank. Our admin panel development services offer excellent value for your investment.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-xl-6">
              <div className="mil-service-item mil-without-lines mil-mb-60">
                <div className="mil-service-icon">
                  <div className="mil-icon-frame mil-icon-frame-md">
                    <img src="/assets/icon/9.svg" alt="icon" />
                  </div>
                </div>
                <div className="mil-service-text">
                  <h5 className="mil-mb-30">
                    <span className="mil-accent">05</span>Maintenance
                  </h5>
                  <p className="conten">
                  Your satisfaction is our top priority. We maintain open communication, keep you informed throughout the development process, and provide reliable post-launch support.
                  </p>
                </div>
              </div>
            </div>

            <div className="row align-items-center">
              {/* <div className="col-md-6 col-xl-6">
                <Link to="/Contact" className="mil-button mil-border mil-mb-30">
                  <span>Talk To Our Web Application Experts</span>
                </Link>
              </div> */}
              <div className="col-md-6 col-xl-6">
                <div className="mil-adaptive-right">
                  {/* <Link to="" className="mil-link mil-mb-30"><span>See More</span><i className="fas fa-arrow-right"></i></Link> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- partners --> */}
      <div class="mil-partners mil-partners-spaces">
        <div class="container">
          <div class="mil-partners-frame">
            <a href="#.">
              <img
                src="/assets/project/21.png"
                alt="partner"
                style={{ width: "130px" }}
              />
            </a>
            {/* <a href="#.">
              <img src="assets/project/2.png" alt="partner" />
            </a> */}
            <a href="#.">
              <img
                src="/assets/project/3.png"
                alt="partner"
                style={{ height: "100px" }}
              />
            </a>
            <a href="#.">
              <img src="/assets/project/11.png" alt="partner" />
            </a>
            <a href="#.">
              <img
                src="/assets/project/12.png"
                alt="partner"
                style={{ width: "100px" }}
              />
            </a>
          </div>
        </div>
      </div>
      {/* <!-- partners end --> */}
      {/* <!-- services --> */}
      <section className="bg-white mil-deco-right mil-p-120-0 shadow shadow-top shadow-lg">
        <div
          className="mil-deco mil-deco-accent"
          style={{ top: "0", left: "5%" }}
        ></div>
        <div className="container">
          <div className="mil-text-center mil-mb-90">
            {/* <span className="mil-suptitle  mil-light mil-suptitle-2 mil-mb-30">
              Always The Best
            </span> */}
            <h2 className="mil-ligh mil-mb-30">
              {" "}
              Why Choose our{" "}
              <span className="mil-accent">Web & App Services</span>{" "}
            </h2>
            <p className="mil-ligh-soft">
              At Bytes Farms, we don't just build websites; we craft digital
              experiences. Our approach to web development is grounded in
              creativity, innovation, and cutting-edge technology. We understand
              that your website is often the first touchpoint for your potential
              customers. Therefore, we ensure that every website we create is a
              true reflection of your brand's essence and values
            </p>
          </div>
          <div className="row mil-mb-30-adapt">
            <div className="col-lg-3">
              <div className="mil-icon-box-head mil-long mil-mob-center mil-mb-60">
                <div className="mil-icon-frame mil-icon-frame-sm mil-ligh">
                  <img src="/assets/icon/sm/6.svg" alt="icon" />
                </div>
                <p className="mil-ligh">Custom Website Development</p>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="mil-icon-box-head mil-long mil-mob-center mil-mb-60">
                <div className="mil-icon-frame mil-icon-frame-sm mil-ligh">
                  <img src="/assets/icon/sm/6.svg" alt="icon" />
                </div>
                <p className="mil-ligh">Responsive Design</p>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="mil-icon-box-head mil-long mil-mob-center mil-mb-60">
                <div className="mil-icon-frame mil-icon-frame-sm mil-ligh">
                  <img src="/assets/icon/sm/6.svg" alt="icon" />
                </div>
                <p className="mil-ligh">E-Commerce Solutions</p>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="mil-icon-box-head mil-long mil-mob-center mil-mb-60">
                <div className="mil-icon-frame mil-icon-frame-sm mil-ligh">
                  <img src="/assets/icon/sm/6.svg" alt="icon" />
                </div>
                <p className="mil-ligh">Content Management Systems (CMS)</p>
              </div>
            </div>

            <div className="col-lg-3">
              <div className="mil-icon-box-head mil-long mil-mob-center mil-mb-60">
                <div className="mil-icon-frame mil-icon-frame-sm mil-ligh">
                  <img src="/assets/icon/sm/6.svg" alt="icon" />
                </div>
                <p className="mil-ligh">Web Application Development</p>
              </div>
            </div>
          </div>
          {/* <div className="mil-text-center">
            <Link
              to="/Contact"
              className="mil-button mil-border mil-ligh mil-mb-30"
            >
              <span>Talk To Our Web App Experts</span>
            </Link>
          </div> */}
        </div>
      </section>
      {/* <!-- services end --> */}

      <Footer />
    </div>
  );
};

export default WebApp;
